import getRoute from '~/utilities/configs/apiMap';
import { profilePageStore } from '~/store/profilePage';
import { authStore } from '~/store/auth';
import type { ProfileItem } from '~/types/profile-page-items';

export default defineNuxtRouteMiddleware(async (to) => {
//   const { id } = to.params;
//   if (!id) {
//     return navigateTo('/404');
//   }

  try {
    const config = useRuntimeConfig();
    const baseUrl = config.public.baseURL as string;
    // const setUrl =
    //   baseUrl + getRoute({ endpont: 'get_profile', id: id as string });
    // const response = await useFetch(setUrl);
    // if (!response.data.value || response.error.value) {
    //   return navigateTo("/404");
    // }

    const useProfilePageStore = profilePageStore();
    // useProfilePageStore.$state.profilePageStore = response.data.value as ProfileItem;
    useProfilePageStore.$state.profilePageData = {
    //   data which was saved on server by the user earlier
    };

    const useAuthStore = authStore();
    useAuthStore.$state.isUserAuth = true;

  } catch (error) {
    return navigateTo('/404');
  }
});